<!--
  PACKAGE_NAME : src\components\devextreme
  FILE_NAME : esp-dx-tree-grid
  AUTHOR : devyoon91
  DATE : 2024-09-20
  DESCRIPTION :
-->
<template>
    <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="selectDiv">
            <DxCheckBox
                class="checkbox"
                text="사용중인 코드만 보기"
                :value="treeGrid.checkBoxValue"
                @value-changed="isUsedFlag"
                @initialized="setDefaultCheck"
            />
        </div>
        <DxTreeList
            id="dxTreeGrid"
            :data-source="treeGrid.dataSource"
            :root-value="-1"
            :ref="treeGrid.refName"
            :expanded-row-keys="treeGrid.expandedRowKeys"
            :selected-row-keys="treeGrid.selectedRowkeys"
            :focused-row-key="treeGrid.focusedRowKey"
            :show-column-headers="treeGrid.showColumnHeaders"
            :show-row-lines="treeGrid.showRowLines"
            :show-column-lines="treeGrid.showColumnLines"
            :show-borders="treeGrid.showBorders"
            :column-auto-width="treeGrid.columnAutoWidth"
            :auto-expand-all="true"
            filter-mode="fullBranch"
            @init-new-row="onInitNewRow"
            @row-prepared="onRowPrepared"
            @row-inserting="onRowInserting"
            @row-inserted="onRowInserted"
            @row-updated="onRowUpdated"
            @row-removed="onRowRemoved"
            @saving="onSaving"
            @toolbar-preparing="onToolbarPreparing"
            :on-row-click="onClickRow"
            :key-expr="treeGrid.keyExpr"
            :parent-id-expr="treeGrid.parentIdExpr"
            :allow-column-resizing="true"
            :height="treeGrid.height"
        >
            <DxSearchPanel
                :visible="treeGrid.searchPanel.visible"
                :placeholder="treeGrid.searchPanel.placeholder"
                :searchVisibleColumnsOnly="treeGrid.searchPanel.searchVisibleColumnsOnly"
                :width="treeGrid.searchPanel.width"
            />
            <DxFilterRow
                :visible="treeGrid.filterRow.visible"
            />
            <DxEditing
                :allow-adding="treeGrid.editing.allowAdding"
                :allow-updating="treeGrid.editing.allowUpdating"
                :use-icons="treeGrid.editing.useIcons"
                :mode="treeGrid.editing.mode"
            />
            <DxSelection :recursive="treeGrid.selection.recursive" mode="single"/>
            <DxScrolling mode="standard"/>
            <DxRowDragging
                :allow-drop-inside-item="treeGrid.rowDragging.allowDropInsideItem"
                :allow-reordering="treeGrid.rowDragging.allowReordering"
                :on-reorder="onReorderRow"
                :show-drag-icons="treeGrid.rowDragging.showDragIcons"
            />
            <!-- 항목 설정 -->
            <template v-for="(column, index) in treeGrid.columns">
                <template v-if="column.multiHeaderNm">
                    <DxColumn :key="index" :caption="$_msgContents(column.i18n, { defaultValue: column.multiHeaderNm })">
                        <DxColumn
                            v-for="(column2, index2) in column.columns"
                            :key="`${index}_${index2}`"
                            :alignment="column2.alignment"
                            :allow-editing="column2.allowEditing"
                            :allow-exporting="column2.allowExporting"
                            :allow-filtering="column2.allowFiltering"
                            :allow-grouping="column2.allowGrouping"
                            :allow-header-filtering="column2.allowHeaderFiltering"
                            :allow-sorting="column2.allowSorting"
                            :calculate-sort-value="column2.calculateSortValue"
                            :calculate-cell-value="column2.calculateCellValue"
                            :calculate-display-value="column2.calculateDisplayCellValue"
                            :caption="$_msgContents(column2.i18n, { defaultValue: column2.caption })"
                            :cell-template="column2.cellTemplate"
                            :css-class="column2.cssClass"
                            :customize-text="column2.customizeText"
                            :data-field="column2.dataField"
                            :data-type="column2.dataType"
                            :edit-cell-template="column2.editCellTemplate"
                            :editor-options="column2.editorOptions"
                            :editor-type="column2.editorType"
                            :filterValue="column2.filterValue"
                            :fixed="column2.fixed"
                            :fixed-position="column2.fixedPosition"
                            :format="column2.format"
                            :group-index="column2.groupIndex"
                            :header-cell-template="column2.headerCellTemplate"
                            :height="column2.height"
                            :set-cell-value="column2.setCellValue"
                            :sort-order="column2.sortOrder"
                            :validation-rules="column2.validationRules"
                            :visible="column2.visible"
                            :width="column2.width"
                            :min-width="column2.minWidth === 0 || column2.minWidth ? column2.minWidth : 50"
                        >
                            <!-- selectBox 옵션 -->
                            <DxLookup
                                v-if="column2.lookup"
                                :data-source="column2.lookup.dataSource"
                                :display-expr="column2.lookup.displayExpr"
                                :value-expr="column2.lookup.valueExpr"
                                :allow-clearing="column2.lookup.allowClearing"
                            />

                            <!-- 헤더필터 설정 -->
                            <DxHeaderFilter v-if="column2.headerFilter" :data-source="column2.headerFilter.dataSource" />

                            <!-- 필수조건 설정 -->
                            <DxRequiredRule
                                v-if="column2.requiredRule"
                                :message="$_msgContents(column2.requiredRule.i18n, { defaultValue: column2.requiredRule.message })"
                            />
                            <!-- 패턴 규칙 설정 -->
                            <DxPatternRule
                                v-if="column2.patternRule"
                                :pattern="column2.patternRule.pattern"
                                :message="column2.patternRule.message"
                            />
                            <!-- 커스텀 규칙 설정 -->
                            <DxCustomRule
                                v-if="column2.customRule"
                                type="custom"
                                :validationCallback="column2.customRule.callback"
                                :message="column2.customRule.message"
                            />
                        </DxColumn>
                    </DxColumn>
                </template>
                <template v-else>
                    <DxColumn
                        :key="index"
                        :alignment="column.alignment"
                        :allow-editing="column.allowEditing"
                        :allow-exporting="column.allowExporting"
                        :allow-filtering="column.allowFiltering"
                        :allow-grouping="column.allowGrouping"
                        :allow-header-filtering="column.allowHeaderFiltering"
                        :allow-sorting="column.allowSorting"
                        :calculate-sort-value="column.calculateSortValue"
                        :calculate-cell-value="column.calculateCellValue"
                        :calculate-display-value="column.calculateDisplayCellValue"
                        :caption="$_msgContents(column.i18n, { defaultValue: column.caption })"
                        :cell-template="column.cellTemplate"
                        :css-class="column.cssClass"
                        :customize-text="column.customizeText"
                        :data-field="column.dataField"
                        :data-type="column.dataType"
                        :edit-cell-template="column.editCellTemplate"
                        :editor-options="column.editorOptions"
                        :editor-type="column.editorType"
                        :filterValue="column.filterValue"
                        :fixed="column.fixed"
                        :fixed-position="column.fixedPosition"
                        :format="column.format"
                        :group-index="column.groupIndex"
                        :header-cell-template="column.headerCellTemplate"
                        :height="column.height"
                        :set-cell-value="column.setCellValue"
                        :sort-order="column.sortOrder"
                        :validation-rules="column.validationRules"
                        :visible="column.visible"
                        :width="column.width"
                        :min-width="column.minWidth === 0 || column.minWidth ? column.minWidth : 50"
                    >
                        <!-- selectBox 옵션 -->
                        <DxLookup
                            v-if="column.lookup"
                            :data-source="column.lookup.dataSource"
                            :display-expr="column.lookup.displayExpr"
                            :value-expr="column.lookup.valueExpr"
                            :allow-clearing="column.lookup.allowClearing"
                        />

                        <!-- 헤더필터 설정 -->
                        <DxHeaderFilter v-if="column.headerFilter" :data-source="column.headerFilter.dataSource" />

                        <!-- 필수조건 설정 -->
                        <DxRequiredRule v-if="column.requiredRule" :message="column.requiredRule.message" />
                        <!-- 패턴 규칙 설정 -->
                        <DxPatternRule
                            v-if="column.patternRule"
                            :pattern="column.patternRule.pattern"
                            :message="column.patternRule.message"
                        />
                        <!-- 커스텀 규칙 설정 -->
                        <DxCustomRule
                            v-if="column.customRule"
                            type="custom"
                            :validationCallback="column.customRule.callback"
                            :message="column.customRule.message"
                        />
                    </DxColumn>
                </template>
            </template>
            <template #removeTemplate="{ data }">
                <div>
                    <DxButton text="" template="<span class='mdi mdi-trash-can'></span>" @click="onDeleteData(data)"/>
                </div>
            </template>
        </DxTreeList>
    </div>
</template>

<script>
import {DxCheckBox} from "devextreme-vue/check-box";
import {DxButton} from "devextreme-vue/button";
import {isSuccess} from '@/plugins/common-lib';
import {
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxSearchPanel,
    DxTreeList,
    DxLookup,
    DxHeaderFilter,
    DxRequiredRule,
    DxCustomRule,
    DxSelection,
    DxRowDragging,
    DxScrolling
} from "devextreme-vue/tree-list";
import store from '@/store';

export default {
    components: {
        DxFilterRow,
        DxSearchPanel,
        DxTreeList,
        DxEditing,
        DxCheckBox,
        DxColumn,
        DxButton,
        DxLookup,
        DxHeaderFilter,
        DxRequiredRule,
        DxCustomRule,
        DxSelection,
        DxRowDragging,
        DxScrolling
    },
    props: {
        treeGrid: {
            type: Object,
            required: true,
        },
    },
    watch: {},
    data() {
        return {
            stylingMode: 'outlined', //outlined, underlined, filled
            callApi: this.treeGrid.callApi == null ? 'CALL_API' : this.treeGrid.callApi, //api 호출
            isReordered: false,
            checkBoxValue: null,
            searchType: {
                defaultObj: {id: 'ROOT', codeValue: 'ROOT', codeNm: '전체'},
                defaultValue: 'ROOT',
                customTypes: {
                    delFl: null,
                },
                paramsData: null,
            },
            showActionButtons: {
                select: true,
            },
        };
    },
    computed: {
    },
    methods: {
        onToolbarPreparing(e) {
            const toolbarItems = e.toolbarOptions.items;
            // Adds a new item

            toolbarItems.forEach(item => {
                if (item.name === 'saveButton') {
                    item.location = 'before';
                    item.sortIndex = 20;
                    item.options.icon = '';
                    item.options.text = '저장';
                    item.showText = 'always';
                    item.options.elementAttr = {class: 'btn_XS default filled', id: this.treeGrid.saveButtonId};
                    item.options.width = '60';
                    item.options.height = '30';
                } else if (item.name === 'addRowButton') {
                    item.location = 'before';
                    item.sortIndex = 10;
                    item.options.icon = '';
                    item.options.text = '추가';
                    item.showText = 'always';
                    item.options.elementAttr = {class: 'btn_XS default filled add1'};
                    item.options.width = '60';
                    item.options.height = '30';
                } else if (item.name === 'revertButton') {
                    item.location = 'before';
                    item.sortIndex = 30;
                    item.options.icon = '';
                    item.options.text = '취소';
                    item.options.elementAttr = {class: 'btn_XS white light_filled '};
                    item.showText = 'always';
                    item.options.width = '60';
                    item.options.height = '30';
                }
            });

            //toolbar custom button push
            if (this.showActionButtons.customButtons) {
                this.showActionButtons.customButtons.forEach((d, i) => {
                    if (!d.sortIndex) d.sortIndex = Number('7' + (i + 1));
                    toolbarItems.push(d);
                });
            }
            toolbarItems.push({
                location: 'before',
                html: `<div class="btn_XS default filled dx-button dx-button-button dx-button-mode-contained dx-widget "
								title="순서 저장"" role="button" style="height: 30px;">
							<div class="dx-button-content"><span>순서 저장</span></div>
							</div>`,
                onClick: this.onSaveSort,
            });

            toolbarItems.push({
                location: 'before',
                html: `<div class="btn_XS white outlined dx-button dx-button-button dx-button-mode-contained dx-widget"
                               title="목록 펼치기" role="button" style="height: 30px;">
                            <div class="dx-button-content"><span class="mdi mdi-folder-open"></span></div>
                          </div>`,
                onClick: this.onUnFoldAll,
            });

            toolbarItems.push({
                location: 'before',
                html: `<div class="btn_XS white outlined dx-button dx-button-button dx-button-mode-contained dx-widget"
                               title="목록 접기"" role="button" style="height: 30px;">
                            <div class="dx-button-content"><span class="mdi mdi-folder"></span></div>
                          </div>`,
                onClick: this.onFoldAll,
            });
            e.toolbarOptions.items = toolbarItems.sort((a, b) => a.sortIndex - b.sortIndex);
        },
        onRowPrepared(e) {
        },
        onClickRow(e) {
        },
        /** @description: 사용중인 코드만 보기 체크 */
        isUsedFlag(e) {
            if (this.$listeners['value-changed']) {
                this.$emit('value-changed', e);
            } else {
                this.treeGrid.checkBoxValue = e.value;
                if (e.value) {
                    this.treeGrid.dataSource = this.treeGrid.dataSource.filter(d => d.delFl === "N");
                } else {
                    this.treeGrid.dataSource = this.treeGrid.originDataSource;
                }
            }
        },
        /** @description: 체크박스 디폴트값 true로 세팅*/
        setDefaultCheck() {
            this.treeGrid.checkBoxValue = true;
        },
        onInitNewRow(e) {
            if (this.$listeners['init-new-row']) {
                this.$emit('init-new-row', e);
            } else {
                let childrenNode = this.$refs[this.treeGrid.refName].instance.getNodeByKey(e.data.parentId).children;
                e.data[this.treeGrid.sortKey] = 0;
                if (e.data.parentId !== -1 && childrenNode.length !== 0) {
                    e.data[this.treeGrid.key] = childrenNode[0].data[this.treeGrid.key];
                    e.data[this.treeGrid.sortKey] = childrenNode[childrenNode.length - 1].data[this.treeGrid.sortKey] + 1;
                    e.data.depth += parentNode.data.depth;
                }else{
                    e.data.depth = 1;
                }
            }
        },
        onRowInserting(e) {
            if (e.data.parentId.toString().length > 10) {
                this.$_Toast('새로 추가된 코드의 하위코드는 저장 후 시도하시기 바랍니다');
                e.cancel = true;
                this.$refs[this.treeGrid.refName].instance.cancelEditData();
            }
        },
        onRowInserted(e) {
            let insertData = {};
            insertData.type = 'insert';
            insertData.key = e.data.id;
            insertData.data = this.$_commonlib.cloneObj(e.data);
            this.changeDatas.push(insertData);
            console.log("onRowInserted", e, this.changeDatas, this.treeGrid.dataSource);
        },
        onSaveSort() {
            if (this.isReordered !== true) {
                return this.$_Toast('순서가 변경된 내역이 없습니다.');
            }
            let changedData = [];
            for (let index = 0; index < this.treeGrid.dataSource.length; index++) {
                let item = this.treeGrid.dataSource[index];
                if (item[this.treeGrid.sortKey] !== index) {
                    item[this.treeGrid.sortKey] = index;
                    changedData.push(item);
                }
            }
            this.updateSort(changedData);
        },
        async updateSort(dataList) {
            let data = dataList;
            let payload;

            payload = {
                actionname: this.treeGrid.apiActionNm.merge,
                data: data,
                loading: true,
            };
            if (await this.$_Confirm('현재 순서를 저장하시겠습니까?')) {
                const res = await store.dispatch(this.callApi, payload);
                if (isSuccess(res)) {
                    if (await this.$_Toast('적용되었습니다')) {
                        if(this.treeGrid.apiActionNm.select){
                            this.onSelectData();
                        }else{
                            this.$emit('row-saved', res);
                        }
                        this.isReordered = false;
                    }
                }
            }
        },
        /* 드래그로 데이터 순서 조정 메소드 */
        onReorderRow(e) {

            console.log("일단 여기 들어오나 ");
            console.log(this.treeGrid.callApi);

            const visibleRows = e.component.getVisibleRows();
            const toIndex = this.treeGrid.dataSource.findIndex(item => item.id === visibleRows[e.toIndex].data.id);
            const fromIndex = this.treeGrid.dataSource.findIndex(item => item.id === e.itemData.id);
            const newCodeList = [...this.treeGrid.dataSource];
            newCodeList.splice(fromIndex, 1);
            newCodeList.splice(toIndex, 0, e.itemData);
            e.itemData[this.treeGrid.sortKey] = e.toIndex;
            this.treeGrid.dataSource = newCodeList;
            this.isReordered = true;
        },
        onRowUpdated(e) {
            if (this.$listeners['row-updated']) {
                this.$emit('rowUpdated', e);
            } else {
                let updateData;
                updateData = this.changeDatas.find(item => item.key === e.data.id);
                if (updateData) {
                    updateData.data = this.$_commonlib.cloneObj(e.data);
                } else {
                    updateData = {};
                    updateData.type = 'update';
                    updateData.key = e.data.id;
                    updateData.data = this.$_commonlib.cloneObj(e.data);
                    this.changeDatas.push(updateData);
                }
            }
        },
        onRowRemoved(e){

        },
        async onSaving(e) {
            if (this.$listeners['saving']) {
                this.$emit('saving', e);
            } else {
                await this.saveData(e);
            }
        },
        async saveData(e){
            e.cancel = true;
            if (e.changes.length) {
                let data = [];
                if (e.changes && e.changes.length > 0) {
                    // 같은 상위코드 내의 하위코드들 코드키 일치 여부 체크
                    if (e.changes[0].type === 'insert' && e.changes.length === 1 && e.changes[0].data.parentId !== -1) {
                        let newChildCodeKey = e.changes[0].data[this.treeGrid.key];
                        let childList = this.$refs[this.treeGrid.refName].instance.getNodeByKey(e.changes[0].data.parentId).children;
                        if(this.treeGrid.key){ // 특정 키를 따라가지 않을 시 key를 빼야함
                            for (let i = 0; i < childList.length; i++) {
                                if (newChildCodeKey !== childList[i].data[this.treeGrid.key]) {
                                    this.$_Toast('같은 상위코드 내의 하위코드는 동일한 코드키로 등록되어야 합니다.');
                                    return;
                                }
                            }
                        }
                    }
                    if (e.changes.length > 1) {
                        for (let i = 1; i < e.changes.length; i++) {
                            if (e.changes[i - 1].type === 'insert') {
                                var parentId1 = e.changes[i - 1].data.parentId;
                                var parentId2 = e.changes[i].data.parentId;
                                var codeKey1 = e.changes[i - 1].data[this.treeGrid.key];
                                var codeKey2 = e.changes[i].data[this.treeGrid.key];
                                var childLength = this.$refs[this.treeGrid.refName].instance.getNodeByKey(parentId1).children.length;
                                if(this.treeGrid.key){
                                    if (parentId1 !== -1 &&
                                        ((!childLength && (parentId1 === parentId2) && (codeKey1 !== codeKey2)) ||
                                            (childLength && (parentId1 === parentId2) && (codeKey1 !== this.$refs[this.treeGrid.refName].instance.getNodeByKey(parentId1).children[0].data[[this.treeGrid.key]])))) {
                                        this.$_Toast('같은 상위코드 내의 하위코드는 동일한 코드키로 등록되어야 합니다.');
                                        return;
                                    }
                                }
                            } else if (e.changes[i - 1].type === 'update') {
                                codeKey1 = e.changes[i - 1].data[this.treeGrid.key];
                                codeKey2 = e.changes[i].data[this.treeGrid.key];
                                if(this.treeGrid.key){
                                    if (codeKey1 !== codeKey2) {
                                        this.$_Toast('같은 상위코드 내의 하위코드는 동일한 코드키로 등록되어야 합니다.');
                                        return;
                                    }
                                }
                            }
                        }
                    }
                    e.changes.forEach(d => {
                        this.$log.debug('e.changes data', d.data);
                        const isMerge = !!this.treeGrid.apiActionNm.merge;
                        const loginId = this.$store.getters.getLoginId;
                        e.changes.forEach(d => {
                            let keyExpr = this.treeGrid.keyExpr ? this.treeGrid.keyExpr : 'id'; // ex) id
                            let dataKey = d.key; // ex) 1
                            let dataMap = d.data; // ex) { value: 100, name: 'test' }

                            // 수정/신규/병합 타입 확인 후 데이터 맵에 등록자/수정자 정보 추가
                            if (d.type === 'update') {
                                d.data.editId = loginId; // 수정자

                                if (isMerge) {
                                    // 병합은 ROW 전체 데이터로 dataMap 설정
                                    e.component
                                        .byKey(dataKey)
                                        .then(data => {
                                            dataMap = Object.assign(data, d.data); // Object.assign() 으로 기존 데이터에 변경된 데이터를 덮어씌움
                                        })
                                        .catch(error => {
                                            this.$log.error(error);
                                        });
                                }
                            } else {
                                d.data.regId = loginId; // 등록자
                                dataKey = null; // FIXME : 신규일 경우 key 값이 null 이어야 함
                            }
                            dataMap[keyExpr] = dataKey; // ex) { id: 1, value: 100, name: 'test' }
                            data.push(dataMap); // ex) [{ id: 1, value: 100, name: 'test' }]
                        });
                    });
                } else {
                    for (let index = 0; index < this.treeGrid.dataSource.length; index++) {
                        this.treeGrid.dataSource[index][this.treeGrid.sortKey] = index;
                    }
                    data = this.treeGrid.dataSource;
                }
                let payload = {
                    actionname: this.treeGrid.apiActionNm.merge,
                    data: data,
                    loading: true,
                };
                let res = await store.dispatch(this.callApi, payload);

                if (isSuccess(res)) {
                    e.component.cancelEditData();
                    if(this.treeGrid.apiActionNm.select){
                        this.onSelectData();
                    }else{
                        this.$emit('row-saved', res);
                    }
                } else if (res.data.header.resCode === 'fail' && res.data.header.resMsg.includes('.U_CODE_U01')) {
                    this.$_Toast('같은 부모의 하위코드 키와 값은 중복될 수 없습니다.');
                } else {
                    this.$_Toast('저장 실패');
                    e.component.cancelEditData();
                }
            }
        },
        async onSelectData(params) {
            let res;
            this.changeDatas = [];
            let paramsData = {sort: this.treeGrid.sort}; // +codeOrd,+codeNm'
            paramsData = {...paramsData, ...params};
            let payload = {
                actionname: this.treeGrid.apiActionNm.select,
                data: {params: paramsData},
                loading: this.treeGrid.apiActionNm.loading ? this.treeGrid.apiActionNm.loading : false,
            };
            if (this.treeGrid.apiActionNm && this.treeGrid.apiActionNm.select) {
                res = await this.$store.dispatch(this.callApi, payload);
            }
            if (isSuccess(res)) {
                // this.$_Toast(this.$_msgContents('CMN_SUC_SAVE'), { icon: 'success' });
                this.treeGrid.dataSource = res.data.data;
            } else {
                this.$_Msg(this.$_msgContents('CMN_ERROR'), { icon: 'error' });
            }
        },
        async onDeleteData(data) {
            let childrenArr = this.selectChildrenById([data.row.node], data.row.node.key);
            let deletedIds = [childrenArr.id, ...childrenArr.childs].map(d => {
                return d;
            });
            let msgContents;
            if (data.row.node.children.length > 0) {
                msgContents = this.$_msgContents('CC.MESSAGE.CFM_DELETE_SELECTED_WITH_CHILD', {defaultValue: '선택한 데이터를 삭제하시겠습니까?<br/>하위 데이터도 함께 삭제됩니다.'});
            } else {
                msgContents = this.$_msgContents('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' });
            }
            if (await this.$_Confirm(msgContents)) {
                this.deleteData(deletedIds);
            } else {
                return false;
            }
        },
        async deleteData(deletedIds) {
            let res;
            let payload = {
                actionname: this.treeGrid.apiActionNm.delete,
                data: {data: deletedIds},
                loading: true,
            };
            if (this.treeGrid.apiActionNm && this.treeGrid.apiActionNm.delete) {
                res = await this.$store.dispatch(this.callApi, payload);
            }

            if (isSuccess(res)) {
                this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
                if(this.treeGrid.apiActionNm.select){
                    this.onSelectData();
                }else{
                    this.$emit('row-removed', res);
                }
            } else {
                this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
        },
        onFoldAll() {
            this.treeGrid.expandedRowKeys = [];
        },
        onUnFoldAll() {
            this.treeGrid.expandedRowKeys = this.treeGrid.dataSource.map(d => {
                return d.id;
            });
        },
        height() {
            if (this.treeGrid.height == null || this.treeGrid.height == undefined) {
                let height = this.topElement('#dxTreeGrid') + this.heightElement('.dx-treelist-header-panel');
                this.treeGrid.height = 'calc(100vh - ' + height + 'px)';
            }
        },
        topElement(e) {
            const divElement = document.querySelector(e);
            const rect = divElement.getBoundingClientRect();
            return rect.top;
        },
        heightElement(e) {
            const divElement = document.querySelector(e);
            const computedStyle = window.getComputedStyle(divElement);
            const divHeight = divElement.offsetHeight;
            const marginTop = parseFloat(computedStyle.marginTop);
            const marginBottom = parseFloat(computedStyle.marginBottom);
            return divHeight + marginTop + marginBottom;
        },
        selectChildrenById(arr, key) {
            return findId(arr, key);

            function findId(arr, key) {
                let output = {};
                arr.forEach(o => {
                    if (o.data.id === key) {
                        output.id = o.data.id;
                        output.childs = (o.children && getArrayOfChildren(o.children, [])) || [];
                    } else if (o.children && o.children.length) {
                        output = findId(o.children, o.data.id);
                    }
                });

                return output;
            }

            function getArrayOfChildren(arr, existingChildren) {
                arr.forEach(o => {
                    existingChildren.push(o.data.id);
                    o.children && getArrayOfChildren(o.children, existingChildren);
                });

                return existingChildren;
            }
        },
    },
    created() {
    },
    mounted() {
        if(this.treeGrid.apiActionNm.select){
            this.onSelectData();
        }
        this.height();
    },
};
</script>
<style scoped>
.sub_new_style01 .page_search_box .inner div {
    display: inline-block;
}

.sub_new_style01 .page_search_box .inner > div {
    vertical-align: middle;
    margin-right: 10px;
}

.selectDiv {
    padding-top: 20px;
    margin-bottom: 10px;
}

.dx-treelist::v-deep .dx-treelist-content .dx-treelist-table .dx-row > td {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.dx-treelist::v-deep .dx-treelist-header-panel {
    padding: 0px !important;
}

.themes_gp_style01 .wrap main .content .page-sub-box:not(.tree-box) {
    padding: 0 25px 0 25px;
}
</style>
<style>
.dx-datagrid .default.atag {
    color: #138deb !important;
    text-decoration: underline;
}

.dx-datagrid .default.atag:hover {
    color: #138deb !important;
}

.addButtonColumn {
    font-size: 13px !important;
    color: #545454 !important;
    font-weight: 700 !important;
}

.dx-treelist .dx-link {
    text-decoration: none;
}
</style>
